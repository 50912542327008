import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import { Button } from 'ui/components'
import { logout } from 'redux/auth/actions'

import styles from './styles'

export default function ({ component: Component, ...rest }) {
  const classes = styles()
  const dispatch = useDispatch()

  const userName = useSelector((state) => state.auth.name)

  const handleLogout = () => {
    dispatch(logout())
  }

  const renderMainBar = () => (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <img
            className={classes.logo}
            src="/assets/images/logos/logo.svg"
            alt="logo"
          />
          <div className={classes.titleContainer}>
            <h2>Painel de monitoramento</h2>

            <p className={classes.versionText}>
              {`version: ${process.env.REACT_APP_PROJECT_VERSION}`}
            </p>
          </div>

          {
            process.env.REACT_APP_LOGIN_DISABLED === 'true'
            ? null
            : (
              <div className={classes.userContainer}>
                <h3>{userName}</h3>
                <Button className={classes.btnLogout} onClick={handleLogout}>SAIR</Button>
              </div>
            )
          }
        </Toolbar>
      </AppBar>
    </div>
  )

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div>
          {renderMainBar()}
          <Component {...matchProps} />
        </div>
      )}
    />
  )
}
