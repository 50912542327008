import React from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from 'redux/auth/actions'
import { isUserLogged } from 'utils/functions'

import useRouter from 'utils/useRouter'

export default function ({ parent: Parent, child, matchProps, ...rest }) {
  const dispatch = useDispatch()
  const router = useRouter()

  const accessToken = useSelector(state => state.auth.access_token)

  function returnToLogin() {
    router.history.push({
      pathname: '/login',
      state: { from: router.location.pathname },
    })
  }

  if (isUserLogged(accessToken)) {
    return <Parent component={child} {...rest} />
  }

  if (accessToken) {
    toast.warn('Seu acesso expirou, faça login novamente.')
  }

  dispatch(logout())
  returnToLogin()
  return null
}
