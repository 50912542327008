import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'

import SignedInLayout from 'ui/layouts/signedIn'

import Login from 'ui/views/Login'
import PasswordChange from 'ui/views/PasswordChange'
import PasswordConfirmRecover from 'ui/views/PasswordConfirmRecover'
import PasswordRecover from 'ui/views/PasswordRecover'

import Home from 'ui/views/Home'

import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'

import SecuredRoute from './securedRoute'

const withLoginRoutes = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/password-change" exact component={PasswordChange} />
    <Route path="/password-confirm-recover" exact component={PasswordConfirmRecover} />
    <Route path="/password-recover" exact component={PasswordRecover} />

    <SecuredRoute
      path={ROUTE_CONSTANTS.HOME}
      exact
      parent={SignedInLayout}
      child={Home}
    />

    <Route component={Login} />
  </Switch>
)

const withoutLoginRoutes = () => (
  <Switch>
    <Route
      path={ROUTE_CONSTANTS.HOME}
      exact
      component={(rest) => <SignedInLayout component={Home} {...rest} />}
    />

    <Route
      render={() => <Redirect to={ROUTE_CONSTANTS.HOME} />}
    />
  </Switch>
)

export default () => {
  return (
    <BrowserRouter>
      {
        process.env.REACT_APP_LOGIN_DISABLED === 'true'
        ? withoutLoginRoutes()
        : withLoginRoutes()
      }
    </BrowserRouter>
  )
}
