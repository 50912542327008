import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`

export const Button = styled.button`
  background-color: ${(props) => props.theme.palette.gray[300]};
  color: ${(props) => props.theme.palette.gray[800]};
  border: 0;
  width: 100%;
  max-width: 40rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  height: 4rem;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: all 0.3s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.primary {
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.contrast};
  }

  &.black {
    background-color: black;
    color: white;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    cursor: default !important;
    background-color: ${(props) => props.theme.palette.gray[100]};
    color: ${(props) => props.theme.palette.gray[500]};
  }

  &.button__loading {
    cursor: default !important;
    background-color: ${(props) => props.theme.palette.gray[100]};
    width: 4rem;
    padding: 0.5rem;
  }

  .button__loader {
    width: 3rem !important;
    height: 3rem !important;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`
