import styled from 'styled-components'

export default styled.div`
  svg {
    margin-right: 0;
    width: 46px;
  }

  .CircularProgressbar-text {
    fill: #000000;
    font-size: 22px;
  }

  .CircularProgressbar-path {
    stroke: ${(props) => props.theme.palette.gray[800]};
  }

  .green {
    .CircularProgressbar-path {
      stroke: #19D315;
    }
  }

  .yellow {
    .CircularProgressbar-path {
      stroke: #F4C109;
    }
  }

  .red {
    .CircularProgressbar-path {
      stroke: #E91010;
    }
  }
`
