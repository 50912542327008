import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Container, { Button } from './styles'

export default ({ loading, disabled, children, variant, onClick, ...rest }) => {
  return (
    <Container>
      <Button
        className={`${loading ? 'button__loading' : ''} ${
          disabled ? 'disabled' : ''
        } ${variant}`}
        type="button"
        {...rest}
        onClick={!disabled ? onClick : null}
      >
        {loading ? <CircularProgress className="button__loader" /> : children}
      </Button>
    </Container>
  )
}
