import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Recaptcha from 'react-recaptcha'
import { withStyles } from '@material-ui/styles'
import { TextField, Typography, Button, CircularProgress } from '@material-ui/core'

import { recoverPassword } from 'redux/auth/actions'
import { recoverPasswd } from 'services/apiAuth'
import { isNetworkError } from 'utils/functions'

import styles from './styles'

const PasswordRecover = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [usernameEmail, setUsernameEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const handleSubmit = async (argUsernameEmail, argRecaptchaToken) => {
    setLoading(true)
    setError(false)

    try {
      const formData = new FormData()
      formData.append('username_email', argUsernameEmail)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', argRecaptchaToken)

      const res = await recoverPasswd(formData)
      if (res.status === 200) {
        setLoading(false)

        dispatch(recoverPassword(res.data.destination))

        return history.push('/password-confirm-recover')
      }
    } catch (err) {
      setLoading(false)
      setIsVerified(false)
      setRecaptchaToken(null)

      if (!isNetworkError(err)) {
        const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao recuperar senha'
        toast.error(messageError)
      }

      return null
    }

    return null
  }

  const verifyCaptcha = response => {
    if (response) {
      setIsVerified(true)
      setRecaptchaToken(response)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <img src="/assets/images/logos/logo.svg" alt="Logo" className={classes.logo} />
        <div className={classes.titleSidebarContainer}>
          <h1 className={classes.titleSidebar}>Recuperar Senha</h1>
          <p className={classes.subtitleSidebar}>Recupere sua senha perdida ou esquecida!</p>
        </div>
      </div>
      <div className={classes.main}>
        <div>
          <h1 className={classes.title}>
            Insira seu email ou nome de usuário:
          </h1>
          <p>Enviaremos para o email cadastrado um código que deverá ser utilizado para recuperar a senha.</p>
        </div>

        <TextField
          id="email"
          label="E-mail"
          className={classes.textField}
          value={usernameEmail}
          onChange={(e) => setUsernameEmail(e.target.value)}
          type="email"
          required={true}
          autoCorrect="off"
          autoCapitalize="off"
        />
        {error ? (
          <p>
            <strong className={classes.error}>Usuário não encontrado nesse aplicativo.</strong>
          </p>
        ) : null}

        <div className={classes.buttonContainer}>
          <div className={isVerified ? classes.noDisplay : ''}>
            <Recaptcha
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              render="explicit"
              expiredCallback={() => {}}
              onloadCallback={() => {}}
              verifyCallback={verifyCaptcha}
              hl="pt-BR"
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classnames(classes.button, !isVerified ? classes.noDisplay : '')}
            onClick={() => handleSubmit(usernameEmail, recaptchaToken)}
            disabled={!!loading}
          >
            {
              loading
              ? <CircularProgress className={classes.progress} />
              : 'Recuperar'
            }
          </Button>

          <Typography>
            Lembrei minha senha.
            {' '}
            <Link to="/login" className={classes.link}>
              Entrar
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(PasswordRecover)
