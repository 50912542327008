import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { useTheme } from '@material-ui/styles'
import { materialTheme } from 'ui/themes/default'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import DvrIcon from '@material-ui/icons/Dvr'

import applicationStatusConstants from 'utils/constants/statusConstants'
import  { getAll } from 'services/apiMonitorStatus'

import styles from './styles'

const formatDate = (date) => {
  const opts = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  return new Intl
    .DateTimeFormat('pt-BR', opts)
    .format(new Date(date))
}

const formatTime = (date) => {
  const opts = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }

  return new Intl
    .DateTimeFormat('pt-BR', opts)
    .format(new Date(date))
}

const subtractMinutes = (dateTime, minutes) => {
  const MS_PER_MINUTE = 60000
  return new Date(dateTime).getTime() - minutes * MS_PER_MINUTE
}

const defineOrder = (content) => {
  switch (content) {
    case applicationStatusConstants.OK:
      return 1
    case applicationStatusConstants.PARTIAL:
      return 2
    case applicationStatusConstants.ERROR:
      return 3
    default:
      return 0
  }
}

const renderApplicationStatus = (status, theme) => {
  let elemStatus = null
  if (status === applicationStatusConstants.ERROR) {
    const color = theme.palette.primary.red
    elemStatus = (
      <React.Fragment>
        <ErrorIcon style={{ color: color,  marginRight: '4px' }} />
        <span>Sistema de origem fora do ar</span>
      </React.Fragment>
    )
  } else if (status === applicationStatusConstants.OK) {
    const color = theme.palette.primary.green
    elemStatus = (
      <React.Fragment>
        <CheckCircleIcon style={{ color: color, marginRight: '4px' }} />
        {applicationStatusConstants.OK}
      </React.Fragment>
    )
  } else if (status === applicationStatusConstants.PARTIAL) {
    const color = theme.palette.primary.yellow
    elemStatus = (
      <React.Fragment>
        <RemoveCircleIcon style={{ color: color, marginRight: '4px' }} />
        Sistema de origem com instabilidade
      </React.Fragment>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {elemStatus}
    </div>
  )
}

export default function ({ _location }) {
  const classes = styles()
  const theme = useTheme()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [applications, setApplications] = useState([])
  const [startPeriod, setStartPeriod] = useState(null)
  const [endPeriod, setEndPeriod] = useState(null)
  const [nextUpdate, setNextUpdate] = useState(null)
  const [loading, setLoading] = useState(false)

  const tableColumns = [
    {
      name: 'app_id',
      label: 'Serviço',
    },
    {
      name: 'success_rate',
      label: 'Porcentagem de Sucesso',
      options: {
        setCellHeaderProps: () => { return { style: { display:'flex', justifyContent:'center' } } },
        setCellProps: () => { return { style: { display:'flex', justifyContent:'center' } } },
        customBodyRender: (value) => (
          `${value}%`
        ),
      },
    },
    {
      name: 'current_status',
      label: 'Status',
      options: {
        customBodyRender: (value) => (
          renderApplicationStatus(value, theme)
        ),
      },
    },
  ]

  const tableOptions = {
    filter: false,
    viewColumns: false,
    search: true,
    responsive: 'simple',
    tableBodyMaxHeight: null,
    download: false,
    print: false,
    rowsPerPage: 1000,
    rowsPerPageOptions: [10, 50, 100, 1000],
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: loading ? 'Carregando' : 'Nenhum registro encontrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: column => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
      },
    },
    sortOrder: {
      name: 'current_status',
      direction: 'asc',
    },
    customSort: (data, colIndex, order) => {
      const invert = order === 'desc' ? 1 : -1

      return data.sort((a, b) => {
        return (
          (defineOrder(a.data[colIndex]) < defineOrder(b.data[colIndex]) ? -1 : 1) * invert
        )
      })
    },
  }

  const listApplications = useCallback(() => {
    setLoading(true)

    getAll(apiKey, userId).then(result => {
      setApplications(() => result.data.apps)

      if (!_.isEmpty(result.data.last_verified)) {
        setStartPeriod(() => formatTime(subtractMinutes(result.data.last_verified, 10)))
        setEndPeriod(() => formatTime(result.data.last_verified))
        setNextUpdate(() => formatDate(result.data.next_verification))
      }

      setLoading(false)
    }).catch(err => {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao listar serviços'
      toast.error(messageError)
    })
  }, [apiKey, userId])

  const savedCallbackListApplications = useRef()

  const callbackListApplications = () => listApplications()
  useEffect(() => {
    savedCallbackListApplications.current = callbackListApplications
  })

  useEffect(() => {
    listApplications()
    const callListApplications = () => savedCallbackListApplications.current()
    const intervalId = setInterval(callListApplications, 600 * 1000)
    return () => clearInterval(intervalId)
  }, [listApplications])

  return (
    <div className="container">
      <div className={classes.titleContainer}>
        <h1 align="center" style={{ padding: '12px', fontSize: '28px' }}>
          <DvrIcon width='30px' color='primary' />
          {' '}
          Monitoramento de Saúde
        </h1>
      </div>

      <Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginBottom: 5 }}>
            <p className={classes.dateVerificationText}>
              Relatório gerado com base no período de 10 minutos
              -
              Início: {startPeriod} e Fim: {endPeriod}
            </p>

            <p className={classes.dateVerificationText}>
              Próxima Atualização:
              {' '}
              {nextUpdate}
            </p>
          </div>
        </div>

        <MuiThemeProvider theme={materialTheme}>
          <MUIDataTable
            data={applications}
            columns={tableColumns}
            options={tableOptions}
          />
        </MuiThemeProvider>
      </Container>
    </div>
  )
}
