import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  table: {
    minWidth: 700,
  },
  dateVerificationText: {
    textAlign: 'center',
    fontSize: 11,
    '@media (min-width: 500px)': {
      fontSize: 15,
      textAlign: 'right',
    },
  },
}))

export default styles
