import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: 30,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 5,
  },
  titleContainer: {
    flexGrow: 1,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  versionText: {
    color: theme.palette.primary.hover,
    bottom: 2,
    position: 'absolute',
  },
  btnLogout: {
    background: 'none !important',

    '&:hover': {
      backgroundColor: 'gray !important',
      opacity: 0.3,
    },
  },
}))

export default styles
