import styled from 'styled-components'

export default styled.div`
  border: 1px solid #CCCCCC;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 350px;

  input {
    background-color: white;
    padding-left: 10px;
    width: 200px;
    height: 40px;
    box-sizing: border-box;
    font-size: 12px;
    color: #9E9E9E;
    border: none;
  }

  input:focus, button:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  button {
    background-color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) => props.theme.palette.gray[700]};
    width: 100px;
  }

  button:hover {
    padding: 5px;
    background-color: ${(props) => props.theme.palette.gray[100]};
  }
`
