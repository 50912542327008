import jwtDecode from 'jwt-decode'
import _ from 'lodash'

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}

export const extractOnlyNumbers = (cpf) => {
  return cpf.replace(/[^\d]/g, '')
}

export const isUserLogged = token => {
  if (_.isEmpty(token)) return false

  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const sleep = m => new Promise(r => setTimeout(r, m))

export const isPasswordValid = (value) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(value)
}

export const getExtensionFileName = (fileName) => {
  const ext = fileName.split('.').pop()
  return ext.split(/[^0-9a-z-_]/i)[0]
}

export const objToQueryString = (objQueryString) => {
  return _
    .chain(objQueryString)
    .pickBy((query) => !(_.isNil(query) || query === ''))
    .map((v, k) => `${k}=${v}`)
    .join('&')
    .value()
}

export const translateBoolean = (bool) => {
  switch (bool) {
    case true: return 'SIM'
    case false: return 'NAO'
    default: return bool
  }
}
