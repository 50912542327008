import axios from "./axiosBase"

const HOST = process.env.REACT_APP_MONITOR_STATUS_API

const headers = (apiKey, newHeaders = {}) => {
  let header = {
    'x-api-key': apiKey,
  }

  if (process.env.NODE_ENV === 'development') {
    header.Authorization = process.env.REACT_APP_MONITOR_STATUS_AUTH_TOKEN
  }

  header = { ...header, ...newHeaders }

  return header
}

export async function getAll(apiKey, _userId) {
  const res = await axios({
    method: 'GET',
    url: `${HOST}/v1/healthcheck/metrics/results`,
    headers: headers(
      apiKey,
    ),
  })

  return res
}
